<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-width: 0.25px;
      }

      .cls-10, .cls-11, .cls-4, .cls-5, .cls-6, .cls-8, .cls-9 {
        stroke-miterlimit: 10;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: url(#inox-gradient-4);
      }

      .cls-8 {
        fill: url(#inox-gradient-5);
      }

      .cls-9 {
        fill: url(#inox-gradient-6);
      }

      .cls-10 {
        fill: url(#inox-gradient-7);
      }

      .cls-11 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient
      id="handle-gradient"
      x1="10.06"
      y1="147.88" 
      x2="15.65"
      y2="147.88"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#97999d"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a7aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient
      id="inox-gradient"
      x1="920.37"
      y1="14114.55"
      x2="889.62"
      y2="14114.55"
      gradientTransform="translate(214.06 -13785.33)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-2"
      data-name="inox-gradient"
      x1="920.37"
      y1="14177.7"
      x2="889.62"
      y2="14177.7"
      gradientTransform="translate(214.06 -13911.65)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-3"
      data-name="inox-gradient"
      x1="920.37"
      y1="14241.78"
      x2="889.62"
      y2="14241.78"
      gradientTransform="translate(214.06 -14039.8)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-4"
      data-name="inox-gradient"
      x1="52.24"
      y1="147.28"
      x2="87.58"
      y2="147.28"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-5"
      data-name="inox-gradient"
      x1="985.62"
      y1="14114.55"
      x2="955.56"
      y2="14114.55"
      gradientTransform="translate(82.87 -13785.33)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-6"
      data-name="inox-gradient"
      x1="985.62"
      y1="14177.7"
      x2="955.56"
      y2="14177.7"
      gradientTransform="translate(82.87 -13911.65)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-7"
      data-name="inox-gradient"
      x1="985.62"
      y1="14241.78"
      x2="955.56"
      y2="14241.78"
      gradientTransform="translate(82.87 -14039.8)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="glass-gradient"
      x1="69.91"
      y1="236.66"
      x2="69.91"
      y2="58.5"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f5"/>
      <stop offset="0.5" stop-color="#dde7e6"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <g id="V21">
      <g id="inoxes" v-if="showInox">
        <rect
          id="inox"
          class="cls-4"
          :x="inoxLX"
          :y="inoxY + inoxHeight/2 - 65"
          :width="inoxLRW"
          height="1.97"/>
        <rect
          id="inox-2"
          data-name="inox"
          class="cls-5"
          :x="inoxLX"
          :y="inoxY + inoxHeight/2"
          :width="inoxLRW"
          height="1.97"/>
        <rect
          id="inox-3"
          data-name="inox"
          class="cls-6"
          :x="inoxLX"
          :y="inoxY + inoxHeight/2 + 65"
          :width="inoxLRW"
          height="1.97"/>
        <rect
          id="inox-4"
          data-name="inox"
          class="cls-6"
          :x="inoxX"
          :y="inoxY"
          :width="inoxWidth"
          :height="inoxHeight"/>
        <rect
          id="inox-5"
          data-name="inox"
          class="cls-8"
          :x="inoxRX"
          :y="inoxY + inoxHeight/2 - 65"
          :width="inoxLRW"
          height="1.97"/>
        <rect
          id="inox-6"
          data-name="inox"
          class="cls-9"
          :x="inoxRX"
          :y="inoxY + inoxHeight/2"
          :width="inoxLRW"
          height="1.97"/>
        <rect
          id="inox-7"
          data-name="inox"
          class="cls-10"
          :x="inoxRX"
          :y="inoxY + inoxHeight/2 + 65"
          :width="inoxLRW"
          height="1.97"/>
      </g>
      <rect
        id="glass"
        class="cls-11"
        :x="glassX"
        :y="glassY"
        :width="glassWidth"
        :height="glassHeight"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
      inoxWidth: 35,
      inoxHeight: 190,
      glassWidth: 24.72,
      glassHeight: 178.16,
    }
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
    inoxX() {
      return this.doorLeftWidth + (this.doorWidth - this.inoxWidth)/2
    },
    inoxY() {
      return this.doorTopHeight + (this.doorHeight - this.inoxHeight)/2
    },
    glassX() {
      return this.doorLeftWidth + (this.doorWidth - this.glassWidth)/2
    },
    glassY() {
      return this.doorTopHeight + (this.doorHeight - this.glassHeight)/2
    },
    inoxLX() {
      return this.showBg ?
        this.doorLeftWidth + this.inoxOffset :
        this.doorLeftWidth + this.leafOffset
    },
    inoxLRW() {
      return this.showBg ?
        (this.doorWidth - this.inoxWidth)/2 - this.inoxOffset:
        (this.doorWidth - this.inoxWidth)/2 - this.leafOffset
    },
    inoxRX() {
      return this.inoxX + this.inoxWidth
    },
  }
}
</script>
